import MothmanImage1 from './img/mothman.png';
import silverbridge from './img/silverbridgecollapse.jpg';
import mothmanstatue from './img/mothmanstatue.jpg';

function Mothman() {
    return (
      <div class="content" style={{ padding: 20 }}>
        <h2>Mothman</h2>
        <img src={MothmanImage1} alt="Mothman" />
        <p>The mothman is a cryptid of West Virginia folklore and legend. It was first reportly seen around Point Pleasant West Virginia in 1966. 
            It has been made legendary due to films such as the 2002 film "The Mothman Prophecies" and numerous documentaries. He is commonly described as being all black with a height over 6ft tall with long wings and red glaring eyes.
        <br/>
        <img src={silverbridge} alt="Silver Bridge Collapse of 1967" />
        <br/>  
            Some believe that the Mothman first started appearing as a warning leading up to the collapse of the Silver Bridge in Point Pleasant West Virginia on December 15th of 1967. While others believe the Mothman may have been responsible.
            The collapse of the Silver Bridge in total resulted in 46 deaths of which two victions were never to found.
        <br/>
        <br/>
            Today you can visit Point Pleasant West Virginia and visit the Mothman museum or go to the Mothman Festival. While there, you can also see a statue created of the Mothman.
            <img src={mothmanstatue} alt="Silver Bridge Collapse of 1967" />
        </p>
      </div>
    );
  }
  
  export default Mothman;