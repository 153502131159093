function About() {
  return (
    <div class="content" style={{ padding: 20 }}>
      <h2>About</h2>
      <p>Based out of Columbus, Ohio. Uknown Supernatural explores all things paranormal, supernatural, and unknown.
         We approach everything from curiousity perspective and provide information about myths, legends, cryptids, the occult, and etc.</p>
    </div>
  );
}
  
export default About;