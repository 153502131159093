import "./App.css";
import './fog.css';
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Outlet,
  useParams
} from 'react-router-dom';
  
import Home from "./pages/Home";
import About from "./pages/About";
import ContactUs from "./pages/ContactUs";
import NavBar from "./pages/NavBar";
import Mothman from "./pages/mothman";

function App() {
  return (
    <>

    <div class="background">
    <div class="fogwrapper">
      <div id="foglayer_01" class="fog">
          <div class="image01"></div>
          <div class="image02"></div>
        </div>
        <div id="foglayer_02" class="fog">
          <div class="image01"></div>
          <div class="image02"></div>
        </div>
        <div id="foglayer_03" class="fog">
          <div class="image01"></div>
          <div class="image02"></div>
        </div>
        </div>
      </div>

      <section>
          <NavBar />
        </section>


        <Router>
          {/* Rest of the code remains same */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/mothman" element={<Mothman />} />
          </Routes>
        </Router>





    </>
  )
}
  
export default App;